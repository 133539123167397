@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

$color-base: #B9BCC4;
$color-current: #2F80ED;
$vertical-breakpoint: 768px;

.xgs-stepped-progress {
  display: flex;
  overflow: hidden;
  padding: 16px 0 0 0;
  text-align: center;
  z-index: 11;

  .xgs-stepped-progress__step {
    list-style-type: none;
    color: $gray1;
    line-height: 20px;
    flex: 1;
    font-size: 14px;
    position: relative;
    padding: 0 2%;

    &:before {
      content: " ";
      padding-top: 2px;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      display: block;
      color: white;
      background: $color-base;
      border-radius: 16px;
      margin: 0 auto 10px;
      position: relative;
      z-index: 11;
    }

    /* connectors */
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      border-top: 2px dashed #CCD1D6;
      position: absolute;
      left: -50%;
      top: 7px;
      z-index: 10;
    }

    &:first-child:after {
      content: none;
    }

    &--completed:not(.xgs-stepped-progress__step--current) {
      &:before {
        background: $color-current;
        color: white;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin-top: -5px;
        content: " ";
      }

      &:after {
        opacity: 0.4;
        border-top: 2px dashed $color-current;
      }
    }

    &--current {
      &:before {
        background: $color-current;
        color: white;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        margin-top: -5px;
      }
    }

    &__icon {
      display: block;
      position: absolute;
      top: 2px;
      left: calc(50% - 7px);
      z-index: 999;
    }
  }

  @mixin vertical-progress {
    display: block;
    padding: 5px 0 0 8px;
    margin: 0;

    .xgs-stepped-progress__step {
      flex: none;
      clear: both;
      text-align: left;
      padding: 0 0 16px 0;
      margin-left: 0;
      min-height: 52px;
      font-size: 16px;

      &:last-child {
        padding: 0;
      }

      &:before {
        float: none;
        display: inline-block;
        margin-right: 10px;
        text-align: center;
        margin-left: 0;
      }

      &:last-child:after {
        border-top: 0;
        border-left: 0;
      }

      &:not(:last-child):after {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 7px;
        z-index: 10;
        border-left: 2px dashed #CCD1D6;
      }

      &--completed:not(.xgs-stepped-progress__step--current) {
        &:before {
          margin-left: -3px;
        }

        .xgs-stepped-progress__step__header {
          margin-top: -36px;
        }
      }

      &--current {
        &:last-child:after {
          border-top: 0;
          border-left: 0;
        }
      }

      &__icon {
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: 999;
      }
    }

    .xgs-stepped-progress__step {
      &__header {
        display: flex;
        justify-content: space-between;
        margin: -32px 0 0 36px;

        &__title {
          white-space: nowrap;
        }

        &__button {
          cursor: pointer;
        }
      }

      &__details {
        margin-left: 36px;
      }

      &--collapsible {
        .xgs-stepped-progress__step__header__title {
          white-space: wrap;
        }

        .xgs-stepped-progress__step__header__title,
        .xgs-stepped-progress__step__header__details {
          max-width: calc(100% - 36px);
        }
      }
    }
  }

  @media (max-width: $vertical-breakpoint) {
    @include vertical-progress;
  }

  &--vertical {
    @include vertical-progress;
  }

  .xgs-trailer-locations__open__container--text-mode {
    @include up-to(769px) {
      margin-left: 36px;
    }
  }
}
