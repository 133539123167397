@import "../../styles/variables.scss";

.xgs-preview-image {
  background-color: rgba(0, 0, 0, 0.8); // Slight transparency for better visibility
  position: fixed; // Use fixed so it stays centered on screen
  z-index: 300;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 90vw; // Ensures it doesn’t overflow horizontally
    max-height: 90vh; // Ensures it doesn’t overflow vertically
    object-fit: contain; // Keeps image proportions
    border-radius: 6px; // Slight rounding for aesthetics
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); // Soft shadow for better visibility
  }

  &__control {
    position: absolute;
    top: 12%;
    right: 30%;
    background-color: $gray4;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    opacity: 0.8;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}
