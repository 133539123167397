@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.xgs-pickup-process {
  &__content {
    position: relative;
    // 100vh - (modal' header height) - (modal' vertical paddings) - (buttons container)
    max-height: calc(100vh - 60px - 64px - 64px);
    overflow-y: auto;
    margin-bottom: 24px;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;

    &__shipper {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      word-break: break-word;

      @include up-to(400px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__checklist-button {
      display: flex;
      gap: 8px;
      align-items: center;
      color: $blue1;
      font-size: 16px;
      min-width: 138px;

      @include up-to(400px) {
        font-size: 14px;
        line-height: 16px;
        min-width: 120px;
      }

      &:hover {
        cursor: pointer;
      }

      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__checklist {
    max-height: calc(100vh - 60px - 64px);
    overflow-y: auto;

    &__header {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__item {
      display: flex;
      gap: 16px;
      line-height: 20px;
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-bottom: 24px;
      }

      &__icon {
        display: flex;
        align-items: center;
        border-radius: 4px;
        width: 32px;
        height: 32px;
        justify-content: center;
        background: rgba(47, 128, 237, 0.16);
        flex-shrink: 0;
      }
    }

    &__value {
      border-radius: 4px;
      border: 1px solid rgba(79, 95, 114, 0.32);
      background: rgba(79, 95, 114, 0.04);
      padding: 8px 24px;
      color: #4F5F72;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin-top: 8px;

      @include up-to(500px) {
        width: 100%;
      }

      &--bol {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 2px;
      }

      &--location {
        flex-grow: 1;
      }
    }

    &__row {
      display: flex;
      gap: 8px;

      @include up-to(500px) {
        flex-wrap: wrap;
        gap: 0;
      }
    }

    .xgs-btn {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__text {
    font-size: 14px;
    margin: 8px 0 24px 0;
  }

  &__confirm-items {
    &__count {
      color: rgba(79, 95, 114, 0.72);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 8px;
    }

    &__list {
      margin-bottom: 16px;

      &__item {
        border-radius: 4px;
        border: 1px solid rgba(79, 95, 114, 0.32);
        padding: 16px;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &--selected.xgs-pickups__block {
          border: 1px solid $blue1;
          background: rgba(47, 128, 237, 0.08);
        }

        &__header-row {
          width: 100%;
          font-weight: 600;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 16px;

          input {
            margin-right: 12px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__add-item-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      width: 100%;
    }

    &__add-item {
      &__row {
        display: flex;
        gap: 16px;
        margin-bottom: 16px;
        max-width: 406px;
        flex-wrap: wrap;

        &--last {
          margin-bottom: 0;
        }

        &__item {
          flex-grow: 0;
          width: calc(50% - 8px);

          @include up-to(400px) {
            width: 100%;
            flex-grow: 1;
          }

          &--width_auto {
            width: auto;
          }

          &--width_flex {
            flex-grow: 1;
          }

          .xgs-labeled-input {
            width: 100%;
          }
        }
      }

      &__field {
        position: relative;
        margin-bottom: 16px;
      }

      &__textarea {
        width: 100%;

        textarea {
          resize: none;
          width: 100% !important;
          height: 100% !important;
        }

        .xgs-textarea {
          width: 100%;

          textarea {
            height: 76px !important;
            min-height: 32px;
          }
        }
      }

      &__buttons {
        display: flex;
        gap: 16px;
        align-items: center;
        width: 100%;

        @include up-to(470px) {
          flex-wrap: wrap;
        }

        .xgs-btn {
          width: 50%;

          @include up-to(470px) {
            width: 100%;
          }
        }
      }
    }
  }

  &__probill-number {
    &__form {
      display: flex;
      gap: 12px;
      align-items: flex-start;
      min-height: 86px;
      margin-top: 24px;

      @include up-to(500px) {
        flex-wrap: wrap;
      }

      .xgs-labeled-input {
        flex-grow: 0;

        @include up-to(480px) {
          width: 100%;
        }
      }

      &__text {
        margin-top: 32px;

        @include up-to(500px) {
          margin-top: 0;
          width: 100%;
          text-align: center;
        }
      }

      .xgs-btn {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: center;
        width: 164px;
        height: 34px;
        margin-top: 24px;
        flex-shrink: 0;

        @include up-to(500px) {
          margin-top: 0;
          width: 100%;
        }
      }
    }
  }

  &__steps {
    position: relative;
    margin-top: 24px;
  }

  &__buttons {
    display: flex;
    gap: 16px;
    padding-left: 44px;

    .xgs-btn {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: center;
      width: 50%;
    }
  }

  .xgs-stepped-progress__step__header__title {
    font-weight: 600;
  }
}
