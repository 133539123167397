@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.xgs-pickup-details {
  &__shipper {
    &__name {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &__pickup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }

  &__start-button {
    width: 174px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  &__items {
    border-left: 4px solid rgba(79, 95, 114, 0.16);
    padding-left: 8px;

    &__row {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      padding: 16px 0;

      &__details {
        display: flex;
        gap: 4px 16px;
        flex-wrap: wrap;;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(79, 95, 114, 0.32);
      }

      .tag {
        justify-self: end;
      }
    }
  }
}
