@import "./variables.scss";
@import "./mixins.scss";

.xgs-form {
  &__container {
    max-width: 540px;
  }

  &__field {
    position: relative;
    margin-bottom: 12px;
    min-height: 80px;
    width: 100%;

    &--no-min-height {
      min-height: 0;
    }

    &--gray-bg {
      padding: 8px;
      background-color: $gray7;
      border-radius: $border-radius;
    }

    &--short {
      width: 200px;

      @include up-to(540px) {
        width: 100%;
      }
    }

    &__input {
      &--error {
        .xgs-input__field {
          border-color: #cc0000;
        }
      }
    }

    &__notes {
      font-size: 12px;
      margin-bottom: 16px;
      color: $gray3;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 24px;

    span {
      color: $red;
    }
  }

  &__radio {
    display: block;
    font-size: 14px;
    width: 100%;

    label {
      margin-right: 12px;
    }
  }

  &__textarea {
    border: 1px solid rgba($gray8, 0.32);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 8px;
    transition: all 100ms;
    font-size: 14px;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2pt $supporting_blue_40 !important;
    }

    &:disabled {
      background-color: rgba($gray8, 0.04);
      color: rgba($gray8, 0.32);
    }
  }

  &__address-block {
    padding: 4px 8px;
    margin: 0 -8px;
    border-radius: $border-radius;
    background-color: $gray7;

    &__container {
      position: relative;
      max-width: 400px;
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 1.2;

      @media (max-width: 510px) {
        margin-left: 0;
        width: 100%;
      }

      .xgs-bol__field {
        margin-bottom: 16px;
      }
    }

    .xgs-labeled-input {
      position: relative;
      margin-bottom: 16px;
    }
  }

  &__buttons {
    margin-top: 36px;

    .xgs-btn {
      @include up-to(600px) {
        width: 100%;
        margin-right: 0;
      }

      &:not(:last-child) {
        margin-right: 16px;

        @include up-to(600px) {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
  }

  &__loading {
    margin-top: 16px;
  }
}

.xgs-item-form {
  box-sizing: border-box;
  max-width: 408px;
  width: 100%;
  height: 100%;
  border: 1px solid #CFD2DE;
  border-radius: $border-radius;
  padding: 16px;
  margin-top: 16px;

  &__row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    @include up-to(360px) {
      flex-wrap: wrap;
    }

    &--buttons {
      @include up-to(540px) {
        flex-wrap: wrap;
      }
    }
  }

  &__field {
    @include up-to(360px) {
      width: 100%;
    }

    &--full-width {
      width: 100%;
    }
  }

  &__button {
    @include up-to(540px) {
      width: 100%;
    }
  }

  &__guidelines-content {
    .xgs-popup__title {
      font-family: "Roboto-Bold";
      margin-bottom: 8px;
    }

    .xgs-popup__content {
      max-width: 430px;
      font-size: 14px;

      img {
        margin: 16px 0 24px;
      }
    }
  }

  &__radio-group {
    position: relative;
    width: 200px;
    flex-shrink: 0;
    margin-right: 20px;
    margin-bottom: 16px;

    @include up-to(920px) {
      width: calc(50% - 20px);
    }

    @include up-to(470px) {
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

