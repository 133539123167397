@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-location-header {
  margin: 0 auto;
  padding: 0;
  margin: 24px 0;

  &__top-block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: rgba(79, 95, 114, 0.04);
    border-radius: $border-radius;
    padding: 16px;
  }

  &__address {
    max-width: calc(100% - 140px);
    color: $gray3;

    &__value {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $darkblue1;
    }

    &__label {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(79, 95, 114, 0.72);
    }
  }

  &__summary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;
    width: 100%;

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      background: rgba(79, 95, 114, 0.04);
      border-radius: $border-radius;
      flex-basis: 0;
      flex-grow: 1;

      &__icon {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

        &--consignees {
          background-color: rgba(47, 128, 237, 0.16);
        }

        &--probills {
          background-color: rgba(151, 71, 255, 0.16);
        }

        &--items {
          background-color: rgba(225, 108, 0, 0.16);
        }

        &--weight {
          background-color: rgba(79, 95, 114, 0.16);
        }
      }

      &__text {
        font-size: 12px;
        color: rgba(79, 95, 114, 0.72);
        line-height: 16px;

        span {
          color: $gray0;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
}
