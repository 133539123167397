
.xgs-pickups {
  &__block {
    border-radius: 4px;
    border: 1px solid rgba(79, 95, 114, 0.32);
    background: rgba(79, 95, 114, 0.04);
    padding: 16px;
    margin-bottom: 8px;

    &--flex {
      display: flex;
      gap: 4px 16px;
      flex-wrap: wrap;
    }
  }

  &__pair {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    word-break: break-word;

    &--pickup-number {
      font-size: 16px;
      margin-bottom: 8px;
    }

    span {
      color: rgba(79, 95, 114, 0.72);
    }
  }
}
