.xgs-alarm-message {
  padding: 16px;
  border: 1px solid rgba(79, 95, 114, 0.32);  

  &__title {
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__body {
    font-size: 14px;
    line-height: 20px;
  }
}